import {Layout} from "../components/Layout";
import React from 'react';
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {graphql} from "gatsby";
import {SectionHero} from "../components/SectionHero";
import {Sections} from "../components/Sections";
import {BackgroundLine} from "../components/BackgroundLine";
import {BooksList} from "../components/BooksList";
import {createSeoData} from "../functions/createSeoData";
import {Breadcrumbs} from "../components/Breadcrumbs";
import {useIntl} from "gatsby-plugin-react-intl";

const styles = require('./insights.module.scss');

export const query = graphql`
    query Insights($locale: String!, $slug: String!) {
        page: strapiSpecialPage(slug: {eq: $slug}, locale: {eq: $locale}) {
            title
            hero {
                ...SectionHero
            }
            sections {
                ...AllSectionsContent
            }
            seo {
                ...Seo
            }
        }
        books: allStrapiBook(filter: {
            locale: {eq: $locale},
            slug: {nin: ["executive-compensation-plans-teardown"]}
        }) {
            nodes {
                slug
                title
                redirectLink
                coverImage {
                    localFile {
                        url
                        childImageSharp {
                            gatsbyImageData(width: 270, height: 400, placeholder: NONE, layout: FIXED)
                        }
                    }
                }
                authors {
                    name
                    avatar {
                        id
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 32, height: 32, layout: FIXED)
                            }
                        }
                    }
                }
            }
        }
    }
`

export default function InsightsPage({data, pageContext}) {
    const intl = useIntl();
    const hero = data.page.hero;
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={data.page.title}
                   jobsCount={pageContext.jobsCount}
                   companyData={pageContext.companyData}
                   seo={createSeoData(data.page.seo)}
                   background={<>
                       <BackgroundLine mobileOn={false}/>
                   </>}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper>
            <SectionHero
                title={hero.title}
                breadcrumbs={<Breadcrumbs className={styles.breadcrumbs}>
                    <Breadcrumbs.Item to="/">{intl.formatMessage({id: 'menu.home'})}</Breadcrumbs.Item>
                    <Breadcrumbs.Item>{intl.formatMessage({id: 'menu.insights'})}</Breadcrumbs.Item>
                </Breadcrumbs>}
                content={hero.content.data}
                ctaButtonRef={hero.ctaButtonRef}
                image={hero.image?.localFile}/>
            <div className={styles.books}>
                <BooksList books={data.books.nodes.map(x => {
                    return {...x, coverImage: x.coverImage.localFile}
                })}/>
            </div>
            <Sections
                sections={data.page.sections ?? []}
            />
        </PageMainColumnWrapper>
    </Layout>
}
